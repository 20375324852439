/* JavaScript for Header */


export class HeaderMenu {
    constructor(element) {
      this.element        = element;
      let yieldContent    = document.querySelector('.yield-wrapper');
      let navigationBlock = document.querySelector('.site-header__navigation-wrapper');
      let calliesLogo     = document.querySelector('.callies-logo__c');
      let closeWord       = this.element.dataset.close;
      let menuWord        = this.element.dataset.menu;
      let footerWrapper   = document.querySelector('.footer-outer-wrapper');
  
    this.element.addEventListener("click", (e) => {
        if (yieldContent.classList.contains('hidden')) {
          yieldContent.classList.remove('hidden');
          navigationBlock.classList.add('hidden');
          calliesLogo.classList.remove('hidden');
          this.element.innerHTML = menuWord;
          footerWrapper.classList.remove('hidden');
        } else {
          yieldContent.classList.add('hidden');
          navigationBlock.classList.remove('hidden');
          calliesLogo.classList.add('hidden');
          this.element.innerHTML = closeWord;
          footerWrapper.classList.add('hidden');
        }
      });
  
    }
  }
 
  export const headerMenu = {
    current: [],
  };
  
  export const init = () => {
    document.addEventListener("turbolinks:load", () => {
        headerMenu.current = [
        ...document.querySelectorAll(
          ".callies__header__menu-wrapper" 
        ),
      ].map((instance) => new HeaderMenu(instance));
    });
  };
  
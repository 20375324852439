import App from "../utilities/main";
import Cookies from "js-cookie";

export const init = () => {
  App.addEventListener("turbolinks:load", () => {
    const cookieNotification = document.getElementById("cookie-notification");

    if (!cookieNotification) { return; };

    const cookieConsentButton = cookieNotification.querySelector(".js-cookie-consent-button");

    const removeNotification = () => {
      document.body.classList.remove("cookie-notification--open");
    };

    cookieConsentButton.addEventListener("click", (e) => {
      e.preventDefault();
      Cookies.set('callies_gdpr_consent', 'true', { expires: 182 }); // 6 month expiration

      removeNotification();
    });
  });
};
 
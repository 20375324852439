import App from "../utilities/main";

export const init = () => {
  
  document.addEventListener("turbolinks:load", () => {
    var applicationWrapper = document.querySelector(".application-wrapper");
    var imageContainer = applicationWrapper.querySelector(".exhibition-image-wrapper");
    
    if(App.state.breakpoint !== "mobile" && imageContainer !== null) {
      var body = applicationWrapper.querySelector(".exhibition-body-area");
      var footer = applicationWrapper.querySelector(".footer-outer-wrapper");
      
      applicationWrapper.classList.add('imagable');
      var bodyOffset = -Math.abs(body.offsetTop)+78;
      var bodyOffsetText = -Math.abs(body.offsetTop)+200;

      // Make sure the user is in a place where images can be seen when added
      document.addEventListener('scroll', (e) => {
        var viewportOffset = body.getBoundingClientRect();
        var top = viewportOffset.top;
        if (window.scrollY <= top) {
          applicationWrapper.classList.add('imagable');
        } else {
          applicationWrapper.classList.remove('imagable');
        }
      });
    
      // Click event for adding an image
      document.addEventListener("click", (e) => {
        if(applicationWrapper.classList.contains('imagable')) {
          var targetElement = e.target;
          var elementName = targetElement.nodeName;
          var randomWidth = Math.random() * (50 - 30) + 30;
          if(elementName !== "IMG") {
            var imageToShow = imageContainer.querySelectorAll(".hidden");
            if(imageToShow.length > 0) {
              if (!body.classList.contains('active')) {
                body.classList.add('active');
                body.style.transform = "translateY("+bodyOffsetText+"px)";
                imageContainer.style.top = bodyOffset+"px";
                footer.style.transform = "translateY("+bodyOffset+"px)";
              }
              var imageToUse = imageToShow[imageToShow.length - 1]
              imageToUse.style.width = randomWidth+"%";
              imageToUse.classList.remove('hidden');
            }
          }
        }
      });

      // Click event for removing an image
      let allImages = document.querySelectorAll(".image__container__image");
      for (let image of allImages) {
        image.addEventListener("click", (e) => {
          if (!image.classList.contains('hidden')) {
            image.classList.add('hidden');
            image.parentNode.prepend(image);
            if(document.querySelectorAll(".hidden").length == document.querySelectorAll(".image__container__image").length+1) {
              body.classList.remove('active');
              body.style.transform = "translateY(0px)";
              footer.style.transform = "translateY(0px)";
            }
          }
        });
      }
    }
  });
};

/* JavaScript for AccordionModule */


export class AccordionModule {
  constructor(element) {
    this.element = element;
    this.accordionItemHeader = this.element.querySelector(".accordion-item__headline-wrapper");
    this.accordionItemBody   = this.element.querySelector(".accordion-item__body-wrapper");
    this.accordionArrow      = this.element.querySelector(".accordion-item__headline__arrow");

    this.accordionItemHeader.addEventListener("click", (e) => {
      if (this.accordionItemBody.classList.contains('active')) {
        this.accordionItemBody.classList.remove('active');
        this.accordionArrow.classList.remove('active');
      } else {
        this.accordionItemBody.classList.add('active');
        this.accordionArrow.classList.add('active');
      }
    });

  }
}

export const accordionModules = {
  current: [],
};

export const init = () => {
  document.addEventListener("turbolinks:load", () => {
    accordionModules.current = [
      ...document.querySelectorAll(
        ".js-accordion-module"
      ),
    ].map((instance) => new AccordionModule(instance));
  });
};

/* JavaScript for VideoModule */

export class VideoModule {
  constructor(element) {
    this.element = element;
    var $posterImages = $('.video_module__poster-image');
    $posterImages.show();
  
    var $vimeos = $('.video_module__iframe--vimeo');
    if ($vimeos.length){
      loadVimeos($vimeos);
    }
    
   function loadVimeos($vimeos){
      $vimeos.each(function(){
        var player = this;
        var $wrapper = $(this).closest('.video-module__video-wrapper');
        var $posterFrame = $wrapper.find('.video_module__poster-image');
        $posterFrame.on('click.videoModule', function(){
          var $this = $(this);
          $this.hide();
          player.play();
        })
      })
    };
  }
}


export const videoModules = {
  current: [],
};

export const init = () => {
  // Initialize any instances of the VideoModule on any given page
  document.addEventListener("turbolinks:load", () => {
    videoModules.current = [
      ...document.querySelectorAll(".js-video-module"),
    ].map((instance) => new VideoModule(instance));
  });
};

/*
export const init = () => {
  // Place your magic module init code here
};
*/
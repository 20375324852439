/* JavaScript for ImageModule */
import Flickity from "flickity";
import 'flickity-imagesloaded';
import 'flickity-fade';

export class ImageModule {
  
  constructor(element) {
    
    this.element = element;
    console.log(this.element[0]);
    this.carousel = this.element.querySelector(".js-image-carousel-module-carousel");
    this.captionContainer = this.element.querySelector(".js-carousel-caption");
    this.nextButton = this.element.querySelector(".js-carousel-next");
    this.prevButton = this.element.querySelector(".js-carousel-prev");

    this.flickity = new Flickity(this.carousel, {
      contain: true,
      wrapAround: true,
      adaptiveHeight: true,
      pageDots: false,
      prevNextButtons: false,
      setGallerySize: true,
      draggable: true,
      imagesLoaded: true,
      lazyLoad: true,
      fade: true,
    });

    this.flickity.resize();
    this.flickity.on( "change", () => {
      this.handleCaption();
    });


    this.nextButton.addEventListener("click", (e) => {
      e.preventDefault();
      this.handleForwardClick(e);
    });

    this.prevButton.addEventListener("click", (e) => {
      e.preventDefault();
      this.handlePreviousClick(e);
    });
  }

  handleCaption() {
    const activeSlide = this.carousel.querySelector('.js-image-carousel-module-slide.is-selected');
    const caption = activeSlide.getAttribute('data-caption');
    this.captionContainer.innerHTML = caption;
  }

  handleForwardClick() {
    this.flickity.next();
  }

  handlePreviousClick() {
    this.flickity.previous();
  }

  remove() {
    this.nextButton.removeEventListener("click", this.handleForwardClick());
    this.prevButton.removeEventListener("click", this.handlePreviousClick());

    imageModules.current = imageModules.current.filter(imageModule => imageModule !== this);
  }
}

export const imageModules = {
  current: [],
};

export const init = () => {
  
  document.addEventListener("turbolinks:load", () => {
    imageModules.current.forEach(imageModule => imageModule.remove());
    imageModules.current = [
      ...document.querySelectorAll(
        ".js-image-carousel-module"
      ),
    ].map((instance) => new ImageModule(instance)); 
  });
};

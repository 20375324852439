import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

class App {
  constructor() {
    this._rootFontSize = this._getRootFontSize();

    // NOTE: breakpoints in rem units
    this.breakpoints = {
      "desktop": 100,
      "tablet": 37.5,
      "mobile": 0,
    };

    // Initial state
    this.state = {
      breakpoint: this._getBreakpoint(),
    };

    // Recognized events
    const config = [
      { parent: document, type: "DOMContentLoaded" },
      { parent: document, type: "turbolinks:load" },
      { parent: document, type: "focusin" },
      { parent: document, type: "focusout" },
      { parent: document, type: "click" },
      { parent: window, type: "scroll", throttle: 100 },
      { parent: window, type: "resize", debounce: 200 },
      { parent: window, type: "breakpoint" },
    ];

    // Build standardized events object
    this.events = config.reduce((acc, { type }) => {
      acc[type] = {};
      return acc;
    }, {});

    // Attach a listener for each registered event type,
    // optionally debouncing the handlers.
    config.forEach((eventConfig) => {
      let executeAllHandlers = (originalEvent) => {
        this.trigger(eventConfig.type, originalEvent);
      };

      if (eventConfig.debounce) {
        executeAllHandlers = debounce(executeAllHandlers, eventConfig.debounce);
      } else if (eventConfig.throttle) {
        executeAllHandlers = throttle(executeAllHandlers, eventConfig.throttle);
      }

      eventConfig.parent.addEventListener(eventConfig.type, executeAllHandlers);
    });

    // Setup custom events
    this.addEventListener("resize", {
      name: "breakpoint-checker",
      handler: () => {
        const previousBreakpoint = this.state.breakpoint;
        const currentBreakpoint = this._getBreakpoint();
        if (currentBreakpoint != this.state.breakpoint) {
          this.state.breakpoint = currentBreakpoint;
          this.trigger("breakpoint", {
            previous: previousBreakpoint,
            current: currentBreakpoint,
          });
        }
      },
    });
  }

  addEventListener(type, e) {
    if (!type || !this.events[type]) {
      console.warn(
        `Could not add event listener because "${type}" is not one of the expected events. Expected:`,
        Object.keys(this.events)
      );
      return;
    }

    const name = e.name || Object.keys(this.events[type]).length;
    const handler = e instanceof Function ? e : e.handler;
    this.events[type][name] = handler;

    return this.removeEventListener.bind(this, type, name);
  }

  removeEventListener(type, data) {
    const name = typeof data === "string" ? data : data.name;
    if (this.events[type] && this.events[type][name]) {
      delete this.events[type][name];
    }
  }

  trigger(type, originalEvent) {
    if (!this.events[type]) {
      return;
    }

    Object.values(this.events[type]).forEach((handler) => {
      if (handler instanceof Function) {
        handler(originalEvent);
      }
    });
  }

  // NOTE: only does a shallow clone
  updateState(update) {
    this.state = Object.assign({}, this.state, update);
  }

  // NOTE: do not call this method directly. Opt instead to use `this.state.breakpoint`
  _getBreakpoint() {
  const viewportWidth = window.innerWidth / this._rootFontSize;
  const [breakpoint] = Object.entries(this.breakpoints).find(
    ([_, minViewportSize]) => {
      return viewportWidth >= minViewportSize;
    }
  );
  return breakpoint;
  }

  // NOTE: css media queries use rem units, so our js recreation of breakpoint should too.
  // We need the root font size in pixels to convert `window.innerWidth` to rem units.
  // `getComputedStyle` causes DOM reflow, though, so we want to minimize how often we check it.
  _getRootFontSize() {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
}

export default new App();

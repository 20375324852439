/* JavaScript for Header */


export class Doodle {
  constructor(element) {
    window.addEventListener('scroll', function() {
      
      this.element = element;
      this.bounding = this.element.getBoundingClientRect();

      if(this.bounding.top >= 0 &&
        this.bounding.left >= 0 &&
        this.bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        this.bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
          this.paths = this.element.querySelectorAll('path, line, polyline');
          this.count = this.paths.length;
          for(var i = 0; i < count; i++) {
            (function(i){
    
                window.setTimeout(function(){
                  this.paths[i].classList.add("show");
                }, i * 3200);
            
            }(i));
        }
      }

    });
  }
}

export const doodles = {
  current: [],
};

export const init = () => {
  document.addEventListener("turbolinks:load", () => {
    doodles.current = [
      ...document.querySelectorAll(
        ".doodle__wrapper"
      ),
    ].map((instance) => new Doodle(instance));
  });
};
import App from "../utilities/main";
import Flickity from 'flickity';
import 'flickity-fade';

let carousels = [];

export class Carousel {
  constructor(carousel) {
    console.log("git here");
    this.carousel = carousel;
    this.nextButtons = this.carousel.querySelectorAll('.js-carousel-next');
    this.prevButtons = this.carousel.querySelectorAll('.js-carousel-prev');
    this.fade = this.carousel.getAttribute('data-fade');

    const Flickity = require("flickity-bg-lazyload");

    this.flickity = new Flickity(carousel, {
      contain: !this.fade,
      draggable: false,
      wrapAround: true,
      cellAlign: 'left',
      pageDots: false,
      prevNextButtons: false,
      bgLazyLoad: true,
      fade: this.fade,
    });

    /* there is an issue with flickity carousels with fade set to true
    when there are only 2 slides and wrap-around is set to true
    here is a workaround https://github.com/metafizzy/flickity-fade/issues/1#issuecomment-486778382*/

    this.nextButtons.forEach((nextButton) => {
      nextButton.addEventListener("click", () => {
      this.handleForwardClick();
      });
    });

    this.prevButtons.forEach((nextButton) => {
      nextButton.addEventListener("click", () => {
      this.handlePreviousClick();
      });
    });

    carousels.push(this);
  };

  handleForwardClick(){
    this.flickity.next();
  }

  handlePreviousClick(){
    this.flickity.previous();
  }

  remove() {
    this.nextButtons.forEach((nextButton) => {
      nextButton.removeEventListener("click", this.handleForwardClick());
    });
    this.prevButtons.forEach((prevButton) => {
      prevButton.removeEventListener("click", this.handlePreviousClick());
    });
    carousels = carousels.filter(carousel => carousel !== this);
  }
};

export const init = () => {
  App.addEventListener("turbolinks:load", () => {
    carousels.forEach(carousel => carousel.remove());

    [...document.querySelectorAll('.js-carousel')].map((elt) =>
      new Carousel(elt)
    );
  });
};
